import React from "react"
import "../styles/long1.css"
import "../styles/mysterybox.css"
import wheel from "../assets/WHEEL.png"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"

const MbSection2 = (props) => {
  return (
    <div className="mb-section-3">
      {props.children}
      <div className={"left-third" + " " + props.padLeftThird}>
        <div className="redline-img"></div>
        <p className="">
          {props.leftText}
        </p>
      </div>
      <div className="right-two-thirds">
        <p className="">
          {props.rightText}
        </p>
      </div>
    </div>
  )
}

const MbTerms = (props) => {

  return <div className={"mb-terms" + " " + props.class}>
    <br />
    <br />
    Entries valid through July 27, 2022 at 5:00pm CST. Offer valid
    only on our LBJ/NTE/NTE 35W TEXpress Lanes. For full Terms &amp;
    Conditions, {/* TODO: change T&C URL */}
    <a href="https://www.texpresslanes.com/lbj-nte-nte-35w-texpress-lanes-mystery-toll-discount-promotion-official-rules/" target={"_blank"}>
      please click here
    </a>
    .{" "}
  </div>
}

const IndexPage = () => (
  <div className="grid-container mysterybox mb-thankyou">
    <Helmet>
      {/* <!--START Lightbox Javascript--> */}
      <script async type='text/javascript' src='https://www.lightboxcdn.com/vendor/8d9917f0-0003-4ba7-baf5-0fb1897cc287/lightbox_inline.js'></script>
    </Helmet>

    <div className="header">
      <div className="header1">
        <div className="logo-div go-logo-div">
          <a aria-current="page" className="logo" href="/">
            <div className="driverprofile-logo"></div>
          </a>
        </div>
      </div>
      <a href="https://www.texpresslanes.com/" target="_blank">
        <div className="header2">What are TEXpress Lanes</div>
      </a>
      <a
        href="https://www.texpresslanes.com/maps/texpress-lanes-map"
        target="_blank"
      >
        <div className="header3">Where are TEXpress Lanes</div>
      </a>
      <a
        href="https://www.texpresslanes.com/navigating-texpress-lanes/how-to-use"
        target="_blank"
      >
        <div className="header4">How to Use TEXpress Lanes</div>
      </a>
    </div>


    <div className="left_space"></div>
    <div className="short1-main">
      <div className="mb-bg">
        <div className="mb-headSection max-width-contain">
          <div className="mb-leftSection">
            <p className="mb-header">
            The mystery discount entry period has ended.
            </p>
            <p className="mb-subtext">
            Thanks to all who entered. Enjoy your toll savings in the month of August on the LBJ, NTE & NTE 35W TEXpress Lanes!
            </p>
            <div className="wheel-bg"></div>
            {/* <img src={wheel} /> */}
          </div>
     

        </div>
      </div>



    </div>
    <div className="right_space"></div>
    <div className="footer">
      <div className="register-footer1">
        <a href="https://www.facebook.com/TEXpressLanes" target="_blank">
          <img
            className="fb-icon"
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          />
        </a>{" "}
        |{" "}
        <a href="https://twitter.com/texpresslanes" target="_blank">
          <img
            class="twitter-icon"
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          />
        </a>{" "}
        |{" "}
        <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">
          Privacy Policy
        </a>{" "}
        │ ©2022 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved
      </div>
    </div>

    {/* <Footer /> */}
  </div>
)

export default IndexPage
